
import { User, Building, Globe, Mail } from "lucide-react";
export const OmOss = () => {
  return <section className="py-16 md:py-24 bg-white relative overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-1/2 bg-gradient-to-b from-primary/5 to-transparent" />
        <div className="absolute -left-40 top-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
        <div className="absolute -right-40 bottom-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
      </div>

      <div className="container mx-auto px-6 md:px-4 relative z-10">
        <div className="text-center max-w-3xl mx-auto mb-12 md:mb-16">
          <h2 className="text-3xl md:text-5xl font-light text-primary-foreground tracking-tight mb-4">
            Abadji Hälso- och Sjukvård
          </h2>
          
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-2xl shadow-lg p-8 md:p-10 relative group hover:shadow-xl transition-all duration-300 border border-primary/10">
            <div className="absolute inset-0 bg-gradient-to-b from-primary/5 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            
            <div className="relative z-10 space-y-8 md:space-y-12">
              <div className="flex flex-col md:flex-row items-center gap-6 md:gap-8">
                <div className="w-24 h-24 md:w-32 md:h-32 rounded-full flex-shrink-0 overflow-hidden">
                  <img src="/lovable-uploads/a4b25162-36bc-498c-b483-46c3489be4f5.png" alt="Nikolas Abadji" className="w-full h-full object-cover" />
                </div>
                <div className="text-center md:text-left space-y-3 md:space-y-4">
                  <h3 className="text-xl md:text-2xl font-light text-primary-foreground">
                    Nikolas Abadji
                  </h3>
                  <p className="text-base md:text-lg text-secondary-foreground/90 leading-relaxed">
                    Legitimerad läkare i både Sverige och Norge samt Specialistläkare i Allmänmedicin.
                  </p>
                  <div className="flex items-center justify-center md:justify-start gap-2 group cursor-pointer">
                    <Mail className="w-5 h-5 text-primary-foreground group-hover:scale-110 transition-transform" />
                    <a href="mailto:nikolas@abadji.se" className="text-base md:text-lg text-primary-foreground hover:text-[#EB5E57] transition-colors">
                      nikolas@abadji.se
                    </a>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
                <div className="flex items-start gap-4 group p-4 rounded-xl hover:bg-primary/5 transition-colors">
                  <div className="w-12 h-12 rounded-xl flex items-center justify-center flex-shrink-0 group-hover:scale-110 transition-transform bg-[#ef2c4c]">
                    <Building className="w-6 h-6 text-white" />
                  </div>
                  <div className="space-y-2">
                    <h4 className="text-lg font-medium text-primary-foreground">Om oss</h4>
                    <p className="text-base text-secondary-foreground/90">Abadji Hälso- och Sjukvård drivs i privat regi av Nikolas Abadji.</p>
                  </div>
                </div>

                <div className="flex items-start gap-4 group p-4 rounded-xl hover:bg-primary/5 transition-colors">
                  <div className="w-12 h-12 rounded-xl flex items-center justify-center flex-shrink-0 group-hover:scale-110 transition-transform bg-[#ef2c4c]">
                    <Globe className="w-6 h-6 text-white" />
                  </div>
                  <div className="space-y-2">
                    <h4 className="text-lg font-medium text-primary-foreground">Abadji Hälso- och Sjukvård</h4>
                    <p className="text-base text-secondary-foreground/90">Org.nr: 559053-1033</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
};
export default OmOss;
