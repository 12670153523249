
import { Hero } from "../components/Hero";
import { LakarKonsult } from "../components/LakarKonsult";
import { Intyg } from "../components/Intyg";
import { Forsakringsmedicin } from "../components/Forsakringsmedicin";
import { Idrottsforening } from "../components/Idrottsforening";
import { OmOss } from "../components/OmOss";
import { Navigation } from "../components/Navigation";

const Index = () => {
  return (
    <main className="min-h-screen bg-white">
      <Navigation />
      <Hero />
      <div id="lakarkonsult">
        <LakarKonsult />
      </div>
      <div id="forsakringsmedicin">
        <Forsakringsmedicin />
      </div>
      <div id="idrottsforening">
        <Idrottsforening />
      </div>
      <div id="intyg">
        <Intyg />
      </div>
      <div id="om-oss">
        <OmOss />
      </div>
    </main>
  );
};

export default Index;
