
import { Stethoscope, Building2, Syringe, Users } from "lucide-react";
const services = [{
  icon: Stethoscope,
  title: "Allmänmedicin",
  description: "Omfattande medicinsk expertis för olika hälsobehov"
}, {
  icon: Building2,
  title: "Företagshälsovård",
  description: "Professionell hälsovård för företag och organisationer"
}, {
  icon: Syringe,
  title: "Vaccination",
  description: "Trygghet för vårdpersonal – vi är bakjour för sjuksköterskor vid behov av stöd vid vaccinering"
}, {
  icon: Users,
  title: "Skolhälsovård",
  description: "Lång erfarenhet av skolhälsovård"
}];

export const LakarKonsult = () => {
  return <section className="py-16 md:py-24 bg-white relative overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-1/2 bg-gradient-to-b from-primary/5 to-transparent" />
        <div className="absolute -left-40 top-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
        <div className="absolute -right-40 bottom-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
      </div>

      <div className="container mx-auto px-6 md:px-4 relative z-10">
        <div className="text-center max-w-3xl mx-auto mb-12 md:mb-16 space-y-4">
          <h2 className="text-3xl md:text-5xl font-light text-primary-foreground tracking-tight">
            LÄKARKONSULT
          </h2>
          <p className="text-base md:text-xl text-secondary-foreground/90 leading-relaxed px-4 max-w-2xl mx-auto">
            Vi utför både korta och långa konsultuppdrag över hela Sverige och Norge inom följande expertisområden:
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-10">
          {services.map((service, index) => <div key={index} className="group relative bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border border-primary/10">
              <div className="absolute inset-0 bg-gradient-to-b from-primary/5 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative z-10 space-y-6">
                <div className="inline-flex items-center justify-center w-14 h-14 rounded-xl text-white group-hover:scale-110 transition-transform duration-300 bg-[#ef2c4c]">
                  <service.icon className="w-7 h-7" />
                </div>
                
                <h3 className="text-xl md:text-2xl font-medium text-primary-foreground">
                  {service.title}
                </h3>
                
                <p className="text-base text-secondary-foreground/90 leading-relaxed">
                  {service.description}
                </p>
              </div>
            </div>)}
        </div>
      </div>
    </section>;
};
export default LakarKonsult;
