
import { useState, useEffect } from 'react';
import { ChevronUp } from "lucide-react";

export const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    // Check initial scroll position when component mounts
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    // Force scroll position to absolute top
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
    // Fallback for mobile browsers that might not support smooth scrolling
    if (typeof window.scroll === 'function') {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        // Simple fallback for older browsers
        window.scrollTo(0, 0);
      }
    }
  };

  return <div className="fixed bottom-4 sm:bottom-8 left-1/2 -translate-x-1/2 z-50 w-full max-w-2xl px-4">
      <nav className={`
        bg-white/90 rounded-full shadow-lg shadow-primary/20
        transition-all duration-300 
        backdrop-blur-md
        mx-auto
        border border-primary/10
        hover:bg-white/95
        ${isScrolled ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}
        w-fit
        relative
      `}>
        <div className="flex items-center justify-center">
          <button 
            onClick={scrollToTop} 
            className="flex items-center justify-center px-5 py-3 text-white bg-[#ef2c4c] hover:bg-[#ef2c4c]/90 transition-colors rounded-full text-base font-medium animate-pulse-gentle"
            aria-label="Scrolla till toppen"
          >
            <ChevronUp className="w-5 h-5 mr-1.5" />
            <span>Upp</span>
          </button>
        </div>
      </nav>
    </div>;
};
export default Navigation;
