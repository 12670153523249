
import { ChevronDown } from "lucide-react";
export const Hero = () => {
  const scrollToNext = () => {
    const nextSection = document.getElementById('lakarkonsult');
    if (nextSection) {
      nextSection.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };
  return <section className="h-screen flex items-center justify-center bg-white relative overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-1/2 bg-gradient-to-b from-primary/10 via-primary/5 to-transparent pointer-events-none" />
        <div className="absolute top-1/4 left-1/4 w-64 md:w-96 h-64 md:h-96 bg-primary/10 rounded-full blur-3xl transform -translate-x-1/2 -translate-y-1/2" />
        <div className="absolute bottom-1/4 right-1/4 w-64 md:w-96 h-64 md:h-96 bg-primary/10 rounded-full blur-3xl transform translate-x-1/2 translate-y-1/2" />
      </div>

      <div className="container mx-auto px-6 md:px-4 py-8 md:py-16 text-center relative z-10">
        <div className="inline-block animate-float mb-0">
          <img src="/lovable-uploads/046a9654-6b36-435a-b064-91b47d6d9106.png" alt="Hjärta" className="w-48 h-48 md:w-72 md:h-72 lg:w-96 lg:h-96 transition-all duration-500" />
        </div>

        <div className="space-y-2 md:space-y-3 max-w-4xl mx-auto -mt-6 md:-mt-12">
          <h1 className="text-5xl sm:text-6xl tracking-tight font-semibold text-[#ef2c4c] md:text-8xl">ABADJI</h1>
          
          <p className="text-xl sm:text-2xl md:text-3xl text-black font-light tracking-wide max-w-2xl mx-auto leading-relaxed">HÄLSO- OCH SJUKVÅRD</p>

          <div className="pt-8 md:pt-12 flex flex-col items-center gap-6">
            
            <button onClick={scrollToNext} aria-label="Scrolla neråt" className="group">
              <ChevronDown className="w-6 h-6 text-[#ef2c4c] group-hover:translate-y-0.5 transition-transform" strokeWidth={2} />
            </button>
          </div>
        </div>
      </div>
    </section>;
};
export default Hero;
