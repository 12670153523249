import { FileText, CheckCircle, AlertCircle } from "lucide-react";
export const Intyg = () => {
  return <section className="py-16 md:py-24 bg-white relative overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-1/2 bg-gradient-to-b from-primary/5 to-transparent" />
        <div className="absolute -left-40 top-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
        <div className="absolute -right-40 bottom-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
      </div>

      <div className="container mx-auto px-6 md:px-4 relative z-10">
        <div className="text-center max-w-3xl mx-auto mb-12 md:mb-16 space-y-4">
          <h2 className="text-3xl md:text-5xl font-light text-primary-foreground tracking-tight">
            INTYG
          </h2>
          <p className="text-base md:text-xl text-secondary-foreground/90 leading-relaxed px-4 max-w-2xl mx-auto">Vi har möjlighet att utfärda de flesta intyg som behövs intygas av en läkare.</p>
        </div>

        <div className="max-w-2xl mx-auto">
          <div className="bg-white rounded-2xl shadow-lg p-8 md:p-10 relative group hover:shadow-xl transition-all duration-300 border border-primary/10">
            <div className="absolute inset-0 bg-gradient-to-b from-primary/5 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            
            <div className="relative z-10 space-y-8">
              <div className="flex items-start gap-6">
                <div className="mt-1">
                  <FileText className="w-8 h-8 text-primary-foreground" />
                </div>
                <div className="space-y-3">
                  <h3 className="text-xl md:text-2xl font-medium text-primary-foreground">
                    Läkarintyg
                  </h3>
                  <p className="text-base text-secondary-foreground/90 leading-relaxed">
                    Läkarintyg utfärdas endast om medicinska skäl för intyget föreligger.
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8 pt-6">
                <div className="flex items-center gap-4 text-secondary-foreground/90 text-base">
                  <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />
                  <span>Professionell bedömning</span>
                </div>
                <div className="flex items-center gap-4 text-secondary-foreground/90 text-base">
                  <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />
                  <span>Omfattande expertis</span>
                </div>
                <div className="flex items-center gap-4 text-secondary-foreground/90 text-base">
                  <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />
                  <span>Snabb handläggning</span>
                </div>
                <div className="flex items-center gap-4 text-secondary-foreground/90 text-base">
                  <AlertCircle className="w-6 h-6 text-[#ef2c4c] flex-shrink-0" />
                  <span>Kräver medicinsk grund</span>
                </div>
              </div>
              
              <div className="mt-8 pt-6 border-t border-primary/10">
                <p className="text-center text-secondary-foreground/90 font-medium">
                  Kontakta oss med din förfrågan!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
};
export default Intyg;