import { Clock, CreditCard, Users } from "lucide-react";
export const Forsakringsmedicin = () => {
  return <section className="py-16 md:py-24 bg-white relative overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-1/2 bg-gradient-to-b from-primary/5 to-transparent" />
        <div className="absolute -left-40 top-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
        <div className="absolute -right-40 bottom-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
      </div>

      <div className="container mx-auto px-6 md:px-4 relative z-10">
        <div className="text-center max-w-3xl mx-auto mb-12 md:mb-16 space-y-6">
          <h2 className="text-3xl md:text-5xl font-light text-primary-foreground tracking-tight">
            FÖRSÄKRINGSMEDICIN
          </h2>
          <p className="text-xl md:text-2xl text-primary-foreground font-medium">
            Har du en privat sjukvårdsförsäkring?
          </p>
          <p className="text-base md:text-lg text-secondary-foreground/90 leading-relaxed max-w-2xl mx-auto">
            Tillsammans med vår samarbetspartner erbjuder vi privatfinansierad sjukvård via de flesta försäkringsbolagen på marknaden idag.
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-2xl shadow-lg p-8 md:p-10 relative group hover:shadow-xl transition-all duration-300 border border-primary/10">
            <div className="absolute inset-0 bg-gradient-to-b from-primary/5 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            
            <div className="relative z-10">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 md:gap-12 py-8">
                <div className="flex flex-col items-center gap-5 group">
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-2xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300 bg-[#ef2c4c]">
                    <Clock className="w-8 h-8 md:w-10 md:h-10 text-white" />
                  </div>
                  <span className="text-base md:text-lg text-primary-foreground font-medium text-center">
                    Tid inom 24 timmar
                  </span>
                </div>
                
                <div className="flex flex-col items-center gap-5 group">
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-2xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300 bg-[#ef2c4c]">
                    <CreditCard className="w-8 h-8 md:w-10 md:h-10 text-white" />
                  </div>
                  <span className="text-base md:text-lg text-primary-foreground font-medium text-center">
                    Privatfinansierad
                  </span>
                </div>
                
                <div className="flex flex-col items-center gap-5 group">
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-2xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300 bg-[#ef2c4c]">
                    <Users className="w-8 h-8 md:w-10 md:h-10 text-white" />
                  </div>
                  <span className="text-base md:text-lg text-primary-foreground font-medium text-center">Knutna till de flesta försäkringsbolagen</span>
                </div>
              </div>

              <div className="text-center mt-10">
                <p className="text-lg md:text-xl text-primary-foreground font-medium">
                  Kontakta ditt försäkringsbolag idag och se över möjligheten att boka en tid hos oss!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
};
export default Forsakringsmedicin;